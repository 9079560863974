@tailwind base;

@tailwind components;

@tailwind utilities;


html,
body {
    font-size: theme('fontSize.100');
    font-family: theme('fontFamily.main');
    color: theme('colors.content.foundation');
    background-color: theme('colors.layers.foreground');
    scroll-behavior: smooth;

    @media (prefers-reduced-motion) {
        scroll-behavior: auto;
    }
}

body {
    overflow-x: hidden;

    /* If there is an element telling the header to be bordered and the site banner is not present */
    @media (max-width: theme('screens.lg')) {
        &:has(*[data-site-header-bordered=true]):not(:has(*[data-site-banner=true])) {
            header[data-site-header=true] {
                border-color: #435289;
            }
        }
    }

    & > main {
        display: flow-root;
    }
}

svg {
    width: auto;
    height: auto;
}

a:hover {
    text-decoration: none;
}
button:focus-visible,
a:focus-visible {
    outline-color: theme('colors.actions.secondary');
}

/* FCSS 1.3 */

address {
    font-style: normal;
}

/* FCSS 1.3 */

button {
    color: inherit;
    line-height: inherit;
    cursor: pointer;
}

/* BUGFIX: Prevents margin-top:0 from interfering with flow classes */

ul {
    margin: 0;
    padding: 0;
}

/* Disables reviews.io widget margin that adds odd spacing to the bottom of a page. */

.ElementsWidget {
    margin: 0 !important;
}

/* Ensures that this element is always on top of the page. */

#headlessui-portal-root {
    z-index: 2000;
    position: relative;
}

/* Stops IOS from adding custom styling to our search input fields. */

input[type='search'] {

    @apply appearance-none;

    outline-offset: 0;
}

span.text-title-one,
span.text-title-two,
span.text-title-three,
span.text-title-four,
span.text-title-five,
span.text-title-six {
    display: inline-block;
}

/* Closes the mega menus on page navigation. */

._js-nav-megamenu-cancel {

    ._js-nav-menu,
    ._js-nav-menu * {
        pointer-events: none !important;
    }
}

.ellipsis {
    word-wrap: normal;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* div with data-lastpass-icon-root="true" is applied by last pass, if we use space-y in fields the margin gets applied. we need to remove it */

div[data-lastpass-icon-root='true'] {
    margin: 0 !important;
}

/* for hiding lastpass icon */

[data-lpignore='true'] {

    ~[data-lastpass-icon-root] {
        display: none !important;
    }
}